<template>
  <div class="step-item">
    <div v-if="number" class="step-item__number">{{ number }}</div>
    <div v-if="img.length" class="step-item__pic">
      <swiper
        v-if="img.length > 1"
        direction="vertical"
        :space-between="16"
        :modules="modules"
        :navigation="{
          nextEl: '.step-item-button-next',
          prevEl: '.step-item-button-prev',
        }"
      >
        <swiper-slide v-for="item in img" :key="item">
          <img :src="item" alt="" />
        </swiper-slide>
        <div class="step-item-navigation">
          <button type="button" class="step-item-button-prev"></button>
          <button type="button" class="step-item-button-next"></button>
        </div>
      </swiper>
      <img v-else-if="img.length === 1" :src="img[0]" alt="" />
    </div>
    <div v-if="title || text" class="step-item__content">
      <div class="step-item__title" v-if="title">{{ title }}</div>
      <div class="step-item__text" v-html="text"></div>
    </div>
    <div class="step-item__content-wysiwyg">
      <slot />
    </div>
  </div>
</template>

<script>
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  props: {
    img: {
      type: Array,
      default: () => [],
    },
    number: String,
    text: String,
    title: {
      type: String,
      required: false,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>

<style lang="scss" scoped>
.step-item {
  display: grid;
  align-content: start;
  gap: 16px;
  padding: var(--widthPadding);
  height: 100%;
  @include md {
    border-radius: var(--radiusOuter);
    background: #f9fafb;
  }
  &__number {
    display: grid;
    place-items: center;
    height: 54px;
    width: 54px;
    font-size: 28px;
    font-weight: 800;
    border-radius: 12px;
    background-color: #e3e6e9;
    font-family: var(--fontSecondary);
  }
  &__pic {
    position: relative;
    height: 320px;
    img {
      width: auto;
      height: 100%;
      display: block;
      margin: 0 auto;
    }
    &:deep(.swiper) {
      height: 100%;
      .step-item {
        &-navigation {
          display: grid;
          grid-auto-flow: row;
          gap: 8px;
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 1;
        }
        &-button {
          &-prev,
          &-next {
            @include reset-button;
            width: 32px;
            height: 32px;
            background-color: #fff;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' fill='none'%3E%3Cpath fill='%231F2229' fill-rule='evenodd' d='M.293.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            border-radius: 8px;
            transform: rotate(90deg);
            filter: invert(1);
            // &.swiper-button-disabled {
            //   opacity: 0.5;
            //   pointer-events: none;
            // }
          }
          &-prev {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
  &__content {
    display: grid;
    gap: 8px;
  }
  &__content-wysiwyg {
    display: grid;
    gap: 16px;
    @include md {
      font-size: 14px;
    }
    :deep(> div) {
      display: grid;
      gap: 8px;
    }
    :deep(h4) {
      font-size: 16px;
      font-weight: 800;
      font-family: var(--fontSecondary);
    }
    :deep(video) {
      display: block;
      margin: 0 auto;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 800;
    font-family: var(--fontSecondary);
  }
  &__text {
    @include md {
      font-size: 14px;
    }
  }
}
</style>

<template>
  <div class="tariffs">
    <TariffPromo v-if="$store.state.region?.region === 'siteSTAVROPOL'" />
    <TariffItem
      v-for="(item, index) in filteredTariffs"
      :key="index"
      :item="item"
    ></TariffItem>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TariffItem from "./TariffItem.vue";
import TariffPromo from "./TariffPromo.vue";

export default {
  components: {
    TariffItem,
    TariffPromo,
  },
  data() {
    return {
      everywhereOnlineAdvantages: [
        {
          frontName: "Telegram и еще 2 безлимитных мессенджера",
          icon: "https://t2.ru/api/media/asset?mediaId=m3620012",
          priority: 10,
        },
        {
          frontName: "ВКонтакте и еще 1 безлимитная соцсеть",
          icon: "https://t2.ru/api/media/asset?mediaId=m4580003",
          priority: 10,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      tariffs: function (state) {
        const copyTariffs = structuredClone(state.api.tariffs);
        const t = copyTariffs.find((t) => t.slug === "everywhere-online");
        if (t?.features?.advantages) {
          t.features.advantages = this.everywhereOnlineAdvantages.concat(
            t.features.advantages
          );
        }
        return copyTariffs;
      },
      status: (state) => state.api.tariffsStatus,
    }),
    filteredTariffs() {
      if (!this.tariffs.length) return [];

      const tariffsToShow = [
        "enough",
        "black",
        "super-online-plus",
        "my-online-plus",
        "my-online",
        "everywhere-online",
        "my-conversation",
        "gaming",
        "premium",
      ];

      return this.tariffs.filter((t) => tariffsToShow.includes(t.slug));
    },
  },
};
</script>

<style lang="scss" scoped>
.tariffs {
  display: grid;
  gap: var(--widthGapV);
  margin-bottom: var(--widthGapV);
  @include md {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>

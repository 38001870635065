<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="apps">
    <div class="apps-head">
      <Card color="primary"><h3>приложения</h3></Card>
      <Card color="grey-dark"></Card>
    </div>
    <div class="apps-body">
      <div class="apps-body__column">
        <Card class="apps-body__card" color="grey-dark">
          <div class="apps-body__section">
            <div class="apps-body__pic">
              <img
                src="@/assets/apps/APPSELLER.svg"
                alt=""
                width="124"
                height="124"
              />
            </div>
            <div class="apps-body__content">
              <h3>APPSELLER</h3>
              <p>Приложение для продавца</p>
            </div>
            <div class="apps-body__desc">
              В приложении можно отслеживать статистику своих продаж, узнавать
              новости и получать подсказки по тарифам.
            </div>
          </div>
          <div class="apps-body__section">
            <div class="apps-body__desc">
              Быстро и просто оформить подключение клиента со своего смартфона
              вам позволит приложение AppSeller.
            </div>
            <div class="apps-body__link">
              <QRLink
                src="/static/demo/qr-app-2.png"
                link="http://onelink.to/twcjcq"
              />
            </div>
          </div>
        </Card>
        <Collapse color="grey-dark">
          <template #head>Преимущества AppSeller</template>
          <template #body>
            <ul>
              <li>
                Безопасность личных данных: данные передаются напрямую в t2
              </li>
              <li>
                Не нужно использовать бумажные бланки, хранить их на торговой
                точке и передавать представителям t2
              </li>
              <li>
                Простые и последовательные шаги при оформлении договора через
                приложение
              </li>
              <li>Процесс занимает всего несколько минут</li>
              <li>
                Горячая линия для решения возникающих вопросов и чат поддержки
              </li>
            </ul>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head
            >Подключение иностранного гражданина в AppSeller</template
          >
          <template #body>
            <ContentSwiper
              class="white"
              :slides-offset-mobile="0"
              :slides-desktop="1"
              height="auto"
            >
              <swiper-slide>
                <ContentStepItem>
                  <video
                    height="560"
                    controls
                    :src="require('@/assets/video/27022025.mp4')"
                    style="aspect-ratio: 260/560"
                  ></video>
                </ContentStepItem>
              </swiper-slide>
            </ContentSwiper>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Регистрация в приложении</template>
          <template #body>
            <ContentSwiper
              class="white"
              :slides-offset-mobile="0"
              :slides-desktop="1"
              height="auto"
            >
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/31.webp')]"
                  number="1"
                  text="Введи номер телефона для регистрации в приложении. Нажми «Далее». Регистрация возможна с номером любого оператора"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/32.webp')]"
                  number="2"
                  text="На указанный номер поступит SMS с кодом подтверждения. Введи код и нажми «Далее»"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/33.webp')]"
                  number="3"
                  text="Заполни поля:
                      <ul>
                        <li>Фамилия;</li>
                        <li>Имя;</li>
                        <li>Отчество (необязательное поле);</li>
                        <li>Код точки продаж;</li>
                        <li>Контактный номер телефона;</li>
                        <li>E-mail (необязательное поле);</li>
                        <li>Твое фото (необязательно).</li>
                        <li>Если есть учетная запись на портале ABC, можешь ее ввести для авторизации на портале (либо пропустить этот шаг)</li>
                      <ul>"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/34.webp')]"
                  number="4"
                  text="После нажатия «Далее» сделай свое фото с паспортом"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/35.webp')]"
                  number="5"
                  text="Сфотографируйся и дождись загрузки изображения. Данные документа должны быть хорошо видны"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/36.webp')]"
                  number="6"
                  text="Подпиши анкету — распишись пальцем внутри рамки"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/37.webp')]"
                  number="7"
                  text="Придумай пароль для входа в приложение"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/38.webp')]"
                  number="8"
                  text="Анкета будет направлена на верификацию ответственному лицу. После подтверждения анкеты (верификации) регистрация учетной записи будет успешно пройдена и пользование приложением станет доступно"
                />
              </swiper-slide>
            </ContentSwiper>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Подключение нового абонента через AppSeller</template>
          <template #body>
            <ContentSwiper
              class="white"
              :slides-offset-mobile="0"
              :slides-desktop="1"
              height="auto"
            >
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/all/25.webp'),
                    require('@/assets/apps/all/27.webp'),
                    require('@/assets/apps/all/28.webp'),
                  ]"
                  number="1"
                >
                  <div>
                    <h4>Шаг 1.1</h4>
                    <div>
                      <p>
                        Выбрать «Продать SIM карту», произвести сканирование
                        штрихкода SIM.
                      </p>
                      <p>
                        На экране отобразится основная информация с возможностью
                        внести изменения
                      </p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/all/18.webp'),
                    require('@/assets/apps/all/20.webp'),
                    require('@/assets/apps/all/22.webp'),
                    require('@/assets/apps/all/04.webp'),
                    require('@/assets/apps/all/05.webp'),
                  ]"
                  number="2"
                >
                  <div>
                    <h4>Шаг 2.1</h4>
                    <p>
                      Запроси у клиента документ, удостоверяющий личность,
                      Выбери необходимый документ в поле «Документы»
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/17.webp')]"
                  number="3"
                >
                  <div>
                    <h4>Шаг 3.1</h4>
                    <p>
                      После формирования документов, ознакомления с договором и
                      нажатия «Подписать договор» появится экран с ожиданием
                      проверки данных клиента в государственных системах
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 3.2</h4>
                    <p>
                      Если получен ответ «Данные достоверны», продолжи
                      оформление договора
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/13.webp')]"
                  number="3"
                >
                  <div>
                    <h4>Шаг 3.3</h4>
                    <p>
                      Если получен ответ «Проверка не пройдена», сообщи: <br />
                      К сожалению, оформление SIM-карты на этот паспорт
                      недоступно, так как данные не прошли проверку
                      информационных государственных систем. Узнать и устранить
                      причины недействительности данных можно на сайте МВД либо
                      на Госуслугах.
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 3.4</h4>
                    <p>
                      Если получен ответ «Проверка будет проведена позже» —
                      продолжи оформление договора и сообщи, что клиент может
                      следить за статусом проверки данных в Личном кабинете
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/16.webp')]"
                  number="3"
                >
                  <div>
                    <h4>Шаг 3.5</h4>
                    <p>
                      Если ты вносил данные клиента вручную, то система попросит
                      распознать предъявленный документ повторно
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 3.6</h4>
                    <p>
                      Если «Данные подтверждены» или «Проверка будет пройдена
                      позже» и клиент согласен со всеми условиями договора, дай
                      клиенту подписать договор графической подписью.
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 3.7</h4>
                    <p>
                      Заверши оформление договора. Если клиент желает оплатить
                      доп. услуги через СБП, выбери SIM-карту для пополнения.
                      Если нет, просто нажми «Далее»
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/15.webp')]"
                  number="4"
                >
                  <div>
                    <h4>Шаг 4.1</h4>
                    <p>
                      После успешной регистрации и оплаты дополнительных услуг
                      (при необходимости) отобразятся следующие экраны
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 4.2</h4>
                    <p>
                      Сообщи клиенту, что на подключенный номер направлено SMS
                      со ссылкой на скачивание подписанных документов. Ссылка
                      активна в течение 72 часов.
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 4.3</h4>
                    <p>
                      Сообщи результат проверки технической возможности
                      абоненту.
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/14.webp')]"
                  number="4"
                >
                  <div>
                    <h4>Шаг 4.4</h4>
                    <p>
                      Предложи оформить заявку на подключение «Домашнего
                      интернета». Если абонент согласен на подключение домашнего
                      интернета, нажми кнопку «Оставить заявку» и заполни
                      все необходимые для ее оформления данные.
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 4.5</h4>
                    <p>
                      Если абонент не согласен на составление заявки, заверши
                      продажу и передай SIM-карту абоненту. SIM-карта
                      активируется автоматически
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
            </ContentSwiper>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head
            >Переход в t2 с сохранением своего номера через AppSeller</template
          >
          <template #body>
            <ContentSwiper
              class="white"
              :slides-offset-mobile="0"
              :slides-desktop="1"
              height="auto"
            >
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/all/26.webp'),
                    require('@/assets/apps/all/02.webp'),
                    require('@/assets/apps/all/03.webp'),
                  ]"
                  number="1"
                >
                  <div>
                    <h4>Шаг 1.1</h4>
                    <div>
                      <p>
                        Выбрать «Перевести в», произвести сканирование штрихкода
                        SIM.
                      </p>
                      <p>
                        Если сканирование не произошло, введи данные SIM вручную
                        (номер телефона + PUK или ICC).
                      </p>
                      <p>
                        На экране отобразится основная информация с возможностью
                        внести изменения
                      </p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/all/12.webp'),
                    require('@/assets/apps/all/19.webp'),
                    require('@/assets/apps/all/21.webp'),
                    require('@/assets/apps/all/23.webp'),
                    require('@/assets/apps/all/24.webp'),
                    require('@/assets/apps/all/06.webp'),
                  ]"
                  number="2"
                >
                  <div>
                    <h4>Шаг 2.1</h4>
                    <p>
                      Укажи номер для переноса в t2 и выбери дату переноса
                      номера
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 2.2</h4>
                    <p>
                      Запроси у клиента документ, удостоверяющий личность,
                      Выбери необходимый документ в поле «Документы»
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/11.webp')]"
                  number="3"
                >
                  <div>
                    <h4>Шаг 3.1</h4>
                    <p>
                      После формирования документов, ознакомления с договором и
                      нажатия «Подписать договор» появится экран с ожиданием
                      проверки данных клиента в государственных системах.
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 3.2</h4>
                    <p>
                      Если получен ответ «Данные достоверны», продолжи
                      оформление договора.
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/10.webp')]"
                  number="3"
                >
                  <div>
                    <h4>Шаг 3.3</h4>
                    <p>
                      Если получен ответ «Проверка не пройдена», сообщи: <br />
                      К сожалению, оформление SIM-карты на этот паспорт
                      недоступно, так как данные не прошли проверку
                      информационных государственных систем. Узнать и устранить
                      причины недействительности данных можно на сайте МВД либо
                      на Госуслугах.
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 3.4</h4>
                    <p>
                      Если получен ответ «Проверка будет проведена позже» —
                      продолжи оформление договора и сообщи, что клиент может
                      следить за статусом проверки данных в Личном кабинете
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/9.webp')]"
                  number="3"
                >
                  <div>
                    <h4>Шаг 3.5</h4>
                    <p>
                      Если ты вносил данные клиента вручную, то система попросит
                      распознать предъявленный документ повторно
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 3.6</h4>
                    <p>
                      Если «Данные подтверждены» или «Проверка будет пройдена
                      позже» и клиент согласен со всеми условиями договора, дай
                      клиенту подписать договор графической подписью.
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 3.7</h4>
                    <p>
                      Заверши оформление договора. Если клиент желает оплатить
                      доп. услуги через СБП, выбери SIM-карту для пополнения.
                      Если нет, просто нажми ”Далее”
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/8.webp')]"
                  number="4"
                >
                  <div>
                    <h4>Шаг 4.1</h4>
                    <p>
                      После успешной регистрации и оплаты дополнительных услуг
                      (при необходимости) отобразятся следующие экраны:
                    </p>
                  </div>
                  <div>
                    <h4>Шаг 4.2</h4>
                    <p>
                      Сообщи клиенту, что на подключенный номер направлено SMS
                      со ссылкой на скачивание подписанных документов. Ссылка
                      активна в течение 72 часов.
                    </p>
                  </div>
                </ContentStepItem>
              </swiper-slide>
            </ContentSwiper>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head
            >Создание заявки на подключение домашнего интернета t2</template
          >
          <template #body>
            <ContentSwiper
              class="white"
              :slides-offset-mobile="0"
              :slides-desktop="1"
            >
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02.webp'),
                  ]"
                  number="1"
                >
                  <div>
                    <h4>Шаг 1</h4>
                    <div>
                      <p>
                        На главной странице выбери «Подключить домашний
                        интернет»
                      </p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-1.webp'),
                  ]"
                  number="2"
                >
                  <div>
                    <h4>Шаг 2</h4>
                    <div>
                      <p>Введи адрес со слов клиента</p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-2.webp'),
                  ]"
                  number="3"
                >
                  <div>
                    <h4>Шаг 3</h4>
                    <div>
                      <p>Проверь техническую возможность</p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-3.webp'),
                  ]"
                  number="4"
                >
                  <div>
                    <h4>Шаг 4</h4>
                    <div>
                      <p>
                        Введи номер клиента и убедись, что все условия соблюдены
                      </p>
                      <p>
                        * Если тарифный план не соответствует критериям
                        подключения, после создания заявки с клиентом свяжется
                        call-центр и предложит выбрать подходящий
                      </p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem number="4">
                  <div>
                    <h4>Важно</h4>
                    <div>
                      <p>
                        Если у клиента нет номера t2 предложи подключиться или
                        перейти со своим номером прямо сейчас. Оформи
                        подключение в приложении AppSeller. Если клиент не
                        готов, нажми «Далее», система предложит внести
                        паспортные данные — нажми «Продолжить без заполнения».
                        Во время своего визита, монтажник привезет клиенту
                        сим-карту t2
                      </p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-4.webp'),
                  ]"
                  number="5"
                >
                  <div>
                    <h4>Шаг 5</h4>
                    <div>
                      <p>Выбери скорость и добавь оборудование</p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-5.webp'),
                  ]"
                  number="6"
                >
                  <div>
                    <h4>Шаг 6</h4>
                    <div>
                      <p>Выбери параметры оборудования</p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-6.webp'),
                  ]"
                  number="7"
                >
                  <div>
                    <h4>Шаг 7</h4>
                    <div>
                      <p>Проверь состав оборудования</p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-7.webp'),
                  ]"
                  number="8"
                >
                  <div>
                    <h4>Шаг 8</h4>
                    <div>
                      <p>Выбери удобную дату для подключения</p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-8.webp'),
                  ]"
                  number="9"
                >
                  <div>
                    <h4>Шаг 9</h4>
                    <div>
                      <p>
                        Добавь доп. контакт, телефон не должен совпадать с
                        номером абонента
                      </p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-9.webp'),
                  ]"
                  number="10"
                >
                  <div>
                    <h4>Шаг 10</h4>
                    <div>
                      <p>Проверь данные заявки</p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-10.webp'),
                  ]"
                  number="11"
                >
                  <div>
                    <h4>Шаг 11</h4>
                    <div>
                      <p>
                        Нажми «Отправить код». Абоненту в SMS поступит код,
                        который нужно ввести в поле «Код из SMS»
                      </p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[
                    require('@/assets/apps/home-internet/Tele2 AppSeller_v4.1 ГАП_t2-02-11.webp'),
                  ]"
                  number="12"
                >
                  <div>
                    <h4>Шаг 12</h4>
                    <div>
                      <p>Готово! <br />Запиши номер заявки для отслеживания</p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem number="12">
                  <div>
                    <div>
                      <p>
                        Сообщи клиенту, что с ним свяжется монтажник для
                        согласования даты и времени подключения домашнего
                        интернета от t2.
                      </p>
                      <p>
                        Если у клиента тариф не соответствует условиям
                        подключения, предупреди, что с ним свяжется сотрудник
                        дистанционного центра для выбора и смены тарифного
                        плана.
                      </p>
                    </div>
                  </div>
                </ContentStepItem>
              </swiper-slide>
            </ContentSwiper>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Что еще может t2 AppSeller</template>
          <template #body>
            <ContentSwiper
              class="white"
              :slides-offset-mobile="0"
              :slides-desktop="1"
            >
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/41.webp')]"
                  title="НОВОСТИ"
                  text="Все новости представлены в разделе «Новости» в виде ленты с фильтрами и возможностью поставить «лайк» новости"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/42.webp')]"
                  title="СТАТИСТИКА ПРОДАЖ"
                  text="Нажмите на вкладку «Твои результаты» на главной странице и вы увидите статистику продаж по дням с возможностью гибко фильтровать список"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/all/43.webp')]"
                  title="ПОРТАЛ ОБУЧЕНИЯ:"
                  text="<p>Портал обучения ABC находится во вкладке «Развитие». На нем вы найдете мотивационные программы, обучающие курсы.</p>
                  <p>При первом входе на портал обучения необходимо ввести логин и пароль от учетной записи, если она есть. Либо зарегистрироваться, заполнив небольшую анкету. После проверки вашей заявки, вы получите email с подтверждением регистрации</p>"
                />
              </swiper-slide>
            </ContentSwiper>
          </template>
        </Collapse>
      </div>
      <div class="apps-body__column">
        <Card class="apps-body__card" color="grey-dark">
          <div class="apps-body__section">
            <div class="apps-body__pic">
              <img src="@/assets/apps/LK.svg" alt="" width="124" height="124" />
            </div>
            <div class="apps-body__content">
              <h3>Личный кабинет <span>t2</span></h3>
              <p>Приложение для клиента</p>
            </div>
          </div>
          <div class="apps-body__section">
            <div class="apps-body__link">
              <QRLink
                src="/static/demo/qr-app-1.svg"
                link="https://onelink.to/ts5aby"
              />
            </div>
          </div>
        </Card>
        <Collapse color="grey-dark">
          <template #head
            >Лучший способ управлять услугами и счетом для абонентов
            t2</template
          >
          <template #body>
            <ul>
              <li>подключение к t2 с саморегистрацией;</li>
              <li>подключение eSIM;</li>
              <li>проверка остатков гигабайтов и минут;</li>
              <li>настройка тарифа;</li>
              <li>подключение или отключение дополнительных услуг;</li>
              <li>возможность делиться гигабайтами с абонентами t2;</li>
              <li>возможность продавать и покупать минуты и ГБ;</li>
              <li>
                удобный виджет для отслеживания остатков минут, гигабайтов и
                баланса.
              </li>
            </ul>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Управление счетом</template>
          <template #body>
            <ul>
              <li>
                пополнение баланса с помощью бесконтактных платежей или
                банковских карт;
              </li>
              <li>установка платежа с привязанной банковской карты;</li>
              <li>использование обещанного платежа;</li>
              <li>просмотр статистики расходов и заказа детализации;</li>
            </ul>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Бонусы</template>
          <template #body>
            <ul>
              <li>
                скидки, кешбэки и подарки от наших партнеров по программе
                «Больше»;
              </li>
              <li>
                уникальные предложения и приглашения на интересные мероприятия.
              </li>
            </ul>
          </template>
        </Collapse>
      </div>
    </div>
  </div>
</template>

<script>
import QRLink from "./QRLink.vue";

import { Navigation, Pagination } from "swiper/modules";
import { SwiperSlide } from "swiper/vue";
import Card from "./Card.vue";
import Collapse from "./Collapse.vue";
import ContentStepItem from "./ContentStepItem.vue";
import ContentSwiper from "./ContentSwiper.vue";

export default {
  components: {
    QRLink,
    SwiperSlide,
    ContentStepItem,
    Card,
    Collapse,
    ContentSwiper,
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style lang="scss" scoped>
.apps {
  padding-top: var(--widthGapH);
  display: grid;
  gap: var(--widthGapH);
  &-head {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      grid-template-columns: 345px 1fr;
      gap: var(--widthGapV);
    }
    &:deep(:nth-child(1)) {
      height: 80px;
      @include md {
        height: 190px;
      }
    }
    &:deep(:nth-child(2)) {
      height: 114px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      background-image: url(@/assets/apps/promo/m.webp);
      @include md {
        height: 190px;
        background-image: url(@/assets/apps/promo/d.webp);
      }
    }
  }
  &-body {
    display: grid;
    gap: var(--widthGapV);
    align-items: start;
    @include md {
      grid-template-columns: repeat(2, 1fr);
      gap: var(--widthGapV);
      &:deep(.card) {
        border-radius: 18px;
      }
    }
    &__column {
      display: grid;
      gap: 8px;
    }
    &__card {
      display: grid;
      gap: var(--widthGapV);
      @include md {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &__section {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 18px;
      @include md {
        grid-auto-flow: row;
        grid-template-columns: auto;
        align-content: start;
      }
    }
    &__content {
      display: grid;
      align-content: start;
      gap: 8px;
    }
    &__pic {
      img {
        display: block;
      }
    }
    &__desc {
      grid-column: 2 span;
      @include md {
        grid-column: auto;
      }
    }
    &__link {
      grid-column: 2 span;
      @include md {
        grid-column: auto;
      }
    }
  }

  &-list {
    display: grid;
    gap: var(--widthGapH);
    &__head {
    }
    &__body {
      display: grid;
      gap: var(--widthGutter);
    }
    &:deep(.collapse) {
      @include md {
        border-radius: var(--radiusOuter);
      }
    }
  }
  &-swiper {
    position: relative;
    display: grid;
    max-width: 1004px;
    margin: 0 calc(var(--widthPadding) * -1);
    @include md {
      margin: 0;
    }
    &:deep(.swiper) {
      width: 100%;
    }
    &:deep(.swiper-custom-navigation) {
      @include md {
        position: relative;
        filter: invert(1);
        margin: 24px auto 0;
      }
    }
    &:deep(.swiper-custom-pagination) {
      @include md {
        display: none;
      }
    }
  }
}
</style>
